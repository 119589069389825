var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-good-table',{staticClass:"position-relative",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.directionIsRTL,"search-options":{
      enabled: false,
    },"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'bulk-selection-wrapper',
      selectionText: 'Participants selected',
      clearSelectionText: 'Clear Selections',
    },"pagination-options":{
      enabled: true,
      perPage: _vm.perPage,
    },"sort-options":"{\n      enabled: true,\n      initialSortBy: {field: 'dateAdded', type: 'asc'}\n  }","style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.onBulkSelectionChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.STATUS_COLOR[props.row.status]}},[_vm._v(" "+_vm._s(props.row.status)+" ")])],1):(props.column.field === 'full_name')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('router-link',{attrs:{"to":{
            name: 'admin-client-participant',
            params: { clientId: _vm.clientId, userId: props.row.id },
          }}},[_vm._v(" "+_vm._s(props.row.full_name)+" ")])],1):(props.column.field === 'action')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-0"},[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-none","to":{
              name: 'admin-client-participant',
              params: { clientId: _vm.clientId, userId: props.row.id },
            }}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"20"}})],1),_c('b-button',{staticClass:"nav action-menu",attrs:{"variant":"outline-none"}},[_c('b-nav-item-dropdown',{staticClass:"btn-icon",scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon","size":"20"}})]},proxy:true}],null,true)},[_c('suspend-user',{attrs:{"user":props.row,"user-id":props.row.id,"is-client-user":""},on:{"updated":_vm.loadItems}}),_c('remove-user',{attrs:{"user":props.row,"user-id":props.row.id,"is-client-user":""},on:{"updated":_vm.loadItems}})],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('b-dropdown',{attrs:{"text":"Selected Actions","variant":"primary"}},[_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.onClickBulkSuspend}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"UserMinusIcon"}}),_c('span',[_vm._v("Suspend")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.onClickBulkUnsuspend}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"UserCheckIcon"}}),_c('span',[_vm._v("Unsuspend")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":_vm.onClickBulkRemove}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"TrashIcon"}}),_c('span',[_vm._v("Remove")])],1)],1)],1)]),_c('aom-delete-confirmation-modal',{ref:"delete-confirmation-modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }