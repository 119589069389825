<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <b-card v-else>
    <b-tabs
      v-model="tabIndex"
      vertical
      content-class="col-12 col-md-10 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-2 col-12"
      nav-class="nav-left"
      fill
    >
      <b-tab>
        <!-- title -->
        <template #title>
          <span class="font-weight-bold">General</span>
        </template>

        <details-tab
          v-if="client"
          :client="client"
        />
      </b-tab> 
      <!--/ Details tab -->

      <!-- Champions tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Champions</span>
        </template>
        <champions :champion-for-client="true" />
      </b-tab> 
      <!-- Champions tab -->

      <!-- Participants tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Participants</span>
        </template>
        <div>
          <div class="text-right">
            <b-button
              class="mb-2"
              variant="primary"
            >
              <b-link
                :to="{ name: 'admin-client-transfer', params: { clientId: clientId } }"
                class="text-white"
              >
                Transfer
              </b-link>
            </b-button>
          </div>
          <participants-list-for-client />
        </div>
      </b-tab>
      <!--/ Participants tab -->

      <!-- Programs tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <span class="font-weight-bold">Programs</span>
        </template>

        <program-list
          ref="programList"
          :client-id="clientId"
        />
      </b-tab>
      <!--/ Programs tab -->
    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard, BButton, BLink } from "bootstrap-vue";
import Champions from "@/views/apps/admin/champion/Champions";
import ParticipantsListForClient from "@/views/apps/admin/participants/ParticipantsListForClient";
import DetailsTab from "./general/DetailsTab.vue";
import ProgramList from "../programs/ProgramsList.vue";

import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import { ClientClass } from '@models/clientClass';
import clientsService from '@/services/clientsService';

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BButton,
    BLink,
    DetailsTab,
    Champions,
    ParticipantsListForClient,
    AomSkeletonLoader,
    ProgramList,
  },
  data() {
    return {
      client: new ClientClass(),
      isLoading: false,
      tabIndex: 0,
      tabs: ['#details', '#champions', '#participants', '#programs']
    };
  },
  computed: {
    clientId() {
      return Number(this.$route.params.clientId);
    }
  },
  watch: {
    tabIndex: {
      handler() {
        const tabName = this.tabs.find((tab,i) => i === this.tabIndex);
        if(this.$route.hash !== tabName) {
          this.$router.push({ hash: `${tabName}` });
        }
      }, 
    },
    '$route.hash': {
      handler(n) {
        if(n) {
          const tabIndex = this.tabs.findIndex(tab => n === tab);
          this.tabIndex = tabIndex;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.fetchClient();
  },
  methods: {
    async fetchClient() {
      try {
        this.isLoading = true;
        const id = this.$route.params.clientId;
        const response = await clientsService.getClientById(id);
        const { data } = response;
        this.client = data;
      } catch (e) {
        this.$toast(makeErrorToast(`Unable to fetch Organisation ${id}.`));
        this.$log(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
