<template>
  <b-card class="details-wrapper">
    <b-row>
      <b-col>
        <h2 class="mb-0">
          {{ client.name }}
        </h2>
        <p class="font-weight-bold text-muted">
          Created {{ formatDateTime(client.created_at) }}
        </p>
      </b-col>
      <b-col
        sm="4"
        class="action"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="deleteClient"
          >
            <feather-icon
              size="16"
              icon="TrashIcon"
              class="mr-50"
            />
            Delete Organisation
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div class="mt-3">
      <h3 class="mb-0">
        Number of Programs
      </h3>
      <b-row class="pt-2 mb-4">
        <b-col sm="4">
          <div class="d-flex align-items-center">
            <b-avatar
              size="80"
              variant="light-primary"
              class="mr-2"
              style="border-radius: 5px !important ;"
            >
              <feather-icon
                size="60"
                icon="BarChartIcon"
              />
            </b-avatar>
            <div class="d-flex flex-column">
              <p class="card-text font-small-3 mb-0">
                Total Programs
              </p>
              <h1 class="font-weight-bolder mb-0 total-programs">
                {{ client.programs_count }}
              </h1>
              
            </div>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="d-flex align-items-center">
            <b-avatar
              size="80"
              variant="light-success"
              class="mr-2"
              style="border-radius: 5px !important ;"
            >
              <feather-icon
                size="60"
                icon="LayersIcon"
              />
            </b-avatar>
            <div class="d-flex flex-column">
              <p class="card-text font-small-3 mb-0">
                Active Programs
              </p>
              <h1 class="font-weight-bolder mb-0 active-programs">
                {{ client.active_programs_count }}
              </h1>
              
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BAvatar
} from "bootstrap-vue";
import moment from 'moment';
import { clientsService } from '@services';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BAvatar
  },

  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    formatDateTime(dateTime) {
      return moment(dateTime).format('DD MMMM YYYY');
    },
    deleteClient() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this organisation? This action cannot be undone. All champions, participants, and program data will be deleted with it.', {
          title: 'Delete Organisation?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const clientId = this.$route.params.clientId;
              await clientsService.deleteClient(clientId);
              this.$toast(makeSuccessToast('Organisation deleted sucessfully.'));
              this.$router.push({name: 'admin-clients'});
            } catch (e) {
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            }
          }
        });
    }
  }
};
</script>


<style lang="scss" scoped>
.details-wrapper {
  border: 1px solid #ccc;
  padding: 20px;
}
.action {
  li {
    list-style-type: none;
  }
}
.active-programs{
 color: var(--green)
}

.total-programs{
  color: var(--purple)
}
</style>

